
import React from 'react';
import '../App.css';
import './Banner.css';
import Typical from 'react-typical';
import logo from './media/logo.png'


function Banner() {
  return (
    <div className='banner-container' id='home'>
      <video src='/videos/sea.mp4' autoPlay loop muted />
      <h1>
        <img src={logo} />
      </h1>
      <h2> for all your plumbing and drainage needs</h2>
      <br />
      <h2> Call or email us</h2>
      {/* <Typical
        steps={[
          '🐙 For all your plumbing', 1000,
          'and Drainlaying', 1000,
          'residential and commercial needs', 1000,
          'Call or Email us', 1000        ]}
        loop={Infinity}
        wrapper="p"
      /> */}
    </div>
  );
}

export default Banner;
