import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-scroll';

// import { Link }from 'react-dom'

function Navbar() {
    const [click, setClick] = useState(false);
    // const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to='home'
                        className='navbar-logo'
                        onClick={closeMobileMenu}
                        smooth={true}
                        offset={-80}
                        duration={1000
                        }>
                        Grant & Co 
                    </Link>
                    <div className='menu-icon'
                        onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='home'
                                className='nav-links'
                                onClick={closeMobileMenu}
                                smooth={true}
                                offset={-80}
                                duration={1000}
                            >
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='service'
                                className='nav-links'
                                onClick={closeMobileMenu}
                                smooth={true}
                                offset={-80}
                                duration={1000}
                            >
                                Services
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='gallery'
                                className='nav-links'
                                onClick={closeMobileMenu}
                                smooth={true}
                                offset={-40}
                                duration={1000}
                            >
                                Gallery
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='about'
                                className='nav-links'
                                onClick={closeMobileMenu}
                                smooth={true}
                                offset={-80}
                                duration={1000}
                            >
                                About
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar;