import React from 'react'
import '../App.css';
import './About.css';

function About() {
    return (
        <div className='about' id='about'>
            <div className="about__container">

                <section className='about-subscription'>
                    <p className="about-subscription-heading">
                        <h1>About Grant and Co</h1>
                        <br />
                        <h3>A locally owned and family run business.</h3>
                        <br />
                        <h4>
                            Owner Roger Grant, is a certifying plumber and drainlayer with more than ten years in the industry, and has been involved in a wide range of commercial and domestic projects across Otago and further afield in Southland and Canterbury.
                            <br /><br />
                            Roger is a good listener, and will offer sound advice and quality workmanship, to provide cost effective solutions for all your plumbing and drainage projects.  </h4>
                    </p>
                    <br />
                    {/* <p className="about-subscription-text">
                        <div class='social-icons'>
                            <i class="fas fa-map-marked"> &nbsp;10000 Octagon</i>
                        </div>
                    </p> */}
                    <p className="about-subscription-text">
                        <div class='social-icons'>

                            <i class="fas fa-phone"> &nbsp;021 226 3840</i>
                        </div>
                    </p>
                    <p className="about-subscription-text">
                        <div class='social-icons'>

                            <i class="fas fa-envelope">&nbsp;
                                <a href="mailto:roger@grantandco.net.nz">roger@grantandco.net.nz</a>
                            </i>
                        </div>
                    </p>
                </section>
            </div>
        </div>
    )
}

export default About
