import React from 'react'
import "../../App.css"
import Banner from "../Banner";
import Cards from '../Cards';
import Footer from '../Footer';
import About from '../About'
import Gallery from '../Gallery'
import { GalleryData } from '../GalleryData';

function Home() {

    return (
        <>
            <Banner />
            <Cards />
            <Gallery slides={GalleryData} />
            <About />
            <Footer />
        </>
    )
}

export default Home;