import work1 from './media/Roger-work-090.jpg'
import work2 from './media/Roger-work-108.jpg'
import work3 from './media/Roger-work-109.jpg'
import work4 from './media/Roger-work-113.jpg'

export const GalleryData = [

    {
        image:
            work1
    },
    {
        image:
            work2
    },
    {
        image:
            work3
    },
    {
        image:
            work4
    }
];