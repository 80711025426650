import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import plumber from './media/plumbing.jpg'
import drain from './media/drainlaying.jpg'
import roofing from './media/roofing.jpg'
import irrigation from './media/irrigation.jpg'
import maintenance from './media/maintenance.jpg'

const plumberserver = ['New home plumbing', <br />, <br />,'Bathroom, kitchen and laundry renovations', <br />, <br />,'Upgrading tapware, shower components, toilet suites and bathroom fittings', <br />, <br />,'Appliance installation: washing machines, fridges, dishwashers, insinkerators, instant hot water units', <br />, <br />,'Conversion from electric storage to gas instantaneous hot water systems', <br />, <br />,'Hot water storage cylinder installation, repair and replacement', <br />, <br />,'Unblocking toilets and fixture waste pipes', <br />, <br />,' Installation, repair and maintenance of spouting', <br />, <br />,'Pumps and Irrigation systems ', <br />,<br />, 'Installation of backflow prevention devices'];

const drainlaingserver = ['Stormwater and wastewater drains for new builds and alterations', <br />, <br />,'Water main repair and replacement',<br />, <br />,'Water storage tank installation',<br />, <br />,'  Sewer pump chambers',<br />, <br />,'Driveway and surface water drainage',<br />, <br />,'Drain unblocking, repairs and replacement',<br />, <br />,' Service and repair of septic tank and wastewater systems'];

function Cards() {
  return (
    <div className='cards' id='service'>
      <br />
      <h1>Reliable and Quality services</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={plumber}
              text={plumberserver}
              label='plumbing'
              path='/'
            />
            <CardItem
              src={drain}
              text={drainlaingserver}
              label='Drainlaying'
              path='/'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;